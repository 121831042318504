.profileCard {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    margin: auto;
    padding: 20px;
    text-align: center;
    margin-top: 5px; /* Ajusta este valor según sea necesario */

}

.profileCard h1 {
    margin: 0;
    color: #333;
    font-family: Arial, sans-serif;
}

.profileCard span {
    font-size: 0.8em;
    color: #666;
}

.profileCard img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-top: 20px;
}

.companyName {
    margin-top: 20px;
    font-size: 1.2em;
    color: #333;
}

.contactInfo .companyName {
    font-weight: bold; /* Hace el texto en negrita */
    font-size: 1.2em; /* Hace el texto más grande */
}
  
.profileButton {
    display: block; /* Hace que los botones ocupen toda la línea */
    margin: 10px auto; /* Centra los botones y agrega espacio */
    padding: 10px 20px; /* Añade relleno dentro de los botones */
    background-color: #00AEEF; /* Color de fondo azul claro */
    color: white; /* Color de texto blanco */
    border: none; /* Elimina el borde del botón */
    border-radius: 5px; /* Bordes redondeados */
    cursor: pointer; /* Cambia el cursor a una mano cuando se pasa sobre el botón */
    font-size: 1em; /* Tamaño de la fuente */
}

.profileButton:hover {
    background-color: #007BBA; /* Cambia el color de fondo al pasar el ratón */
}
